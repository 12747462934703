import React from 'react'
import '../../components/styles/global.scss'
import SegmentedControl from '../../components/common/SegmentedControl'
import Downloadable from '../../components/common/Downloadable'
import Layout from '../../components/common/Layout'

export default function ResearchET() {
    return (
        <Layout
        active='research-essential-thrombocythemia'
        title='PharmaEssentia Medical Affairs | Areas of Research'
        description='Educational information relating to PharmaEssentia’s therapeutic areas of interest, including polycythemia vera, essential thrombocythemia, and interferon use in myeloproliferative neoplasms.'
        socialTitle='PharmaEssentia Medical Affairs | Areas of Research'
        socialDescription='Educational information relating to PharmaEssentia’s therapeutic areas of interest, including polycythemia vera, essential thrombocythemia, and interferon use in myeloproliferative neoplasms.'
        pathname='/research'
        >
            <section>
                <div className='inner'>
                    <h1>Areas of Research</h1>
                    <p>This section contains educational information relating to PharmaEssentia’s therapeutic areas of interest.</p>
                    <SegmentedControl buttons='areas-of-research' active='research-essential-thrombocythemia'/>
                    <h3>Essential Thrombocythemia (ET)</h3>
                    <p>ET is a rare, chronic myeloproliferative neoplasm (MPN) characterized by genetic mutations, which trigger thrombocytosis and possible thrombotic and hemorrhagic complications.</p>
                    <p>ET is caused by an overproduction of hematopoietic cells due to the presence of genetic mutations, including <i>JAK2</i>, <i>CALR</i>, and <i>MPL</i>. This leads to an increase in platelets and, in some cases, white blood cells.</p>
                    <p>Diagnosing ET can be challenging due to the similarity of the disease to other MPNs. The 2016 WHO diagnostic criteria (and the 2022 ICC MPN subcommittee recommended updates) for ET focus on both clinical and molecular characteristics and emphasize the need for accurate bone marrow biopsies, which can help differentiate a diagnosis of ET from early/prefibrotic myelofibrosis.</p>
                    <p>The goal of comprehensive disease management in ET is to achieve control of blood counts, symptom management, and prevent progression and transformation. Current therapies include platelet-reducing/cytoreductive agents and interferon alfa.</p>                
                </div>
            </section>
        </Layout>
    )
}